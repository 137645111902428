<template>
    <div class="container">
        <section class="section">
            <div class="container">
                <h1 class="title">BRAIN Initiative Archives and Programs</h1>
                <p>
                    The Brain Research Through Advancing Innovative Neurotechnologies® Initiative, or The BRAIN Initiative®, is a partnership between Federal and non-Federal entities with a common goal of accelerating the development of innovative neurotechnologies. 
                </p>
            </div>
        </section>
        <section
            class="section"
            v-for="(links, category_name) in links"
            v-bind:key="category_name"
        >
            <div class="container">
                <h2 class="title is-4">{{ category_name }}</h2>
                <div class="columns is-multiline">
                    <div
                        v-for="link in links"
                        v-bind:key="link.name"
                        class="column is-4-desktop is-6-tablet"
                    >
                        <div class="card">
                            <div class="card-content link-card">
                                <div class="media">
                                    <div class="media-left">
                                        <span class="icon">
                                            <i :class=link.icon></i>
                                        </span>
                                    </div>
                                    <div class="media-content">
                                        <div class="title is-5">
                                            <router-link
                                                v-if="link.description"
                                                :to="'/links/' + link.name"
                                                >{{ link.name }}</router-link
                                            >
                                            <a v-else :href="link.link">
                                                {{ link.name }}
                                            </a>
                                        </div>
                                        <div class="content">
                                            {{ link.blurb }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import links from "@/brain_initiative_links.js";
export default {
    data() {
        return { links: links.categories };
    },
};
</script>

<style>
.link-card {
    min-height: 10em;
}
</style>