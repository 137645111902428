// Add the import for the base URL
import { ASSETS_BASE_URL } from '@/assets.js';

const bossDb = {
    "name": "BossDB",
    "link": "https://github.com/aplbrain/neurodatarehack2023",
    "blurb": "Archive for Electron Microscopy and X-ray Microtomography data",
    "icon": "fas fa-database"
};

const bil = {
    "name": "Brain Image Library (BIL)",
    "link": "http://www.brainimagelibrary.org",
    "blurb": "Archive for Optical Microscopy data",
    "icon": "fas fa-database"
};

const nemo = {
    "name": "Neuroscience Multi-Omic Data Archive (NeMO)",
    "link": "https://nemoarchive.org",
    "blurb": "Archive for Multi-omics data",
    "icon": "fas fa-database"
};

const dabi = {
    "name": "Data Archive for the Brain Initiative (DABI)",
    "link": "https://dabi.loni.usc.edu",
    "blurb": "Archive for Invasive Device data",
    "icon": "fas fa-database"
};

const openNeuro = {
    "name": "OpenNeuro",
    "link": "https://openneuro.org",
    "blurb": "Archive for Magnetic Resonance Imaging data",
    "icon": "fas fa-database"
};

const openNeuroPet = {
    "name": "OpenNeuroPet",
    "link": "https://openneuropet.github.io",
    "blurb": "Archive for Positron Emission Tomography data",
    "icon": "fas fa-database"
};

const dandi = {
    "name": "Distributed Archives for Neurophysiology Data Integration (DANDI)",
    "link": "https://www.dandiarchive.org",
    "blurb": "Archive for Cellular neurophysiology data",
    "icon": "fas fa-database"
};

const nemar = {
    "name": "Neuroelectromagnetic Data Archive and Tools Resource (NEMAR)",
    "link": "https://nemar.org",
    "blurb": "Archive for Human EEG and MEG data",
    "icon": "fas fa-database"
};

const brainIntiativeInformatics = {
    "name": "BRAIN Initiative Informatics Program",
    "link": "https://braininitiative.nih.gov/research/data-science-and-informatics/informatics-program",
    "blurb": "The NIH BRAIN Initiative informatics program supports development of the informatics infrastructure for NIH BRAIN Initiative research.",
    "icon": "fas fa-external-link-alt"
};

const brainConnects = {
    "name": "BRAIN CONNECTS",
    "link": "https://braininitiative.nih.gov/research/neuroimaging-technologies-across-scales/brain-initiative-connectivity-across-scales",
    "blurb": "The BRAIN Initiative Connectivity Across Scales (BRAIN CONNECTS) program aims to develop the research capacity and technical capabilities to generate wiring diagrams that can span entire brains across multiple scales.",
    "icon": "fas fa-external-link-alt"
};

const biccn = {
    "name": "BRAIN Initiative Cell Census Network (BICCN)",
    "link": "https://biccn.org/",
    "blurb": "NIH's Brain Research through Advancing Innovative Neurotechnologies (BRAIN) Initiative - Cell Census Network (BICCN) aims to provide researchers and the public with a comprehensive reference of the diverse cell types in human, mouse, and non-human primate brain.",
    "icon": "fas fa-external-link-alt"
};

const bican = {
    "name": "BRAIN Initiative Cell Atlas Network (BICAN)",
    "link": "https://braininitiative.nih.gov/research/tools-and-technologies-brain-cells-and-circuits/brain-initiative-cell-atlas-network",
    "blurb": "The BRAIN Initiative Cell Atlas Network (BICAN) aims to build reference brain cell atlases that will be widely used throughout the research community, providing a molecular and anatomical foundational framework for the study of brain function and disorders.",
    "icon": "fas fa-external-link-alt"
};

const categories = {
    "Archives": [
        bossDb,
        bil,
        nemo,
        dabi,
        openNeuro,
        openNeuroPet,
        dandi,
        nemar
    ],

    "BRAIN Initiative Programs": [
        brainIntiativeInformatics,
        brainConnects,
        biccn,
        bican
    ]
}


let links = Object.values(categories).reduce((a, b) => ([...Object.values(a), ...Object.values(b)]), []);

let all_links = {};
links.forEach((t) => {
    all_links[t.name.toLowerCase()] = t
});


export default { all_links, categories};


