<template>
    <div>
        <BrainInitiative />
    </div>
</template>

<script>
import BrainInitiative from "@/components/BrainInitiative.vue";

export default {
    components: { BrainInitiative }

}
</script>

<style>

</style>