<template>
    <div>
        <ToolsPage />
    </div>
</template>

<script>
import ToolsPage from "@/components/ToolsPage.vue";

export default {
    components: { ToolsPage }

}
</script>

<style>

</style>